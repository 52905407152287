<template>
    <div>
        <table>
            <tr v-for="(row, rowIndex) in reversedLayout"
                :key="rowIndex"
            >
                <td v-for="(symbol, reelIndex) in row"
                    :key="reelIndex"
                >

                    <div :style='{ backgroundImage: `url( ${imagePath(rowIndex, reelIndex)} )` }'
                         class="layout__symbol"
                    />
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        layout: {
            type: Array,
            required: true,
        },
        positions: {
            type: Array,
            required: true,
        },
        symbolsPath: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            symbol: {
                size: 80
            }
        }
    },
    computed: {
        rows() {
            return this.reversedLayout.length;
        },
        reels() {
            return this.reversedLayout[0].length;
        },
        reversedLayout() {
            return this.layout[0].map((col, i) => this.layout.map(row => row[i]))
        },
    },
    methods: {
        wildSymbol(rowIndex, reelIndex) {
            const megaWildSymbol = this.megaWildSymbol(rowIndex, reelIndex);

            if (megaWildSymbol)
                return megaWildSymbol

            return this.commonWildSymbol(rowIndex, reelIndex);
        },
        commonWildSymbol(rowIndex, reelIndex) {
            const findSymbol = this.positions.find(position => (position.reel === reelIndex) && (position.line === rowIndex));

            if (findSymbol) {
                return findSymbol.symbol;
            }

            return null;
        },
        megaWildSymbol(rowIndex, reelIndex) {
            const findSymbol = this.positions.find(position => (position.reel === reelIndex) && (position.symbol === 'G'));

            if (findSymbol) {
                if (rowIndex === 0)
                    return 'G';
                else if (rowIndex === 1) {
                    return 'D';
                } else {
                    return 'O';
                }
            }

            return null;
        },
        getSymbol(rowIndex, reelIndex) {
            const wildSymbol = this.wildSymbol(rowIndex, reelIndex);

            if (wildSymbol) {
                return wildSymbol;
            } else {
                return this.layout[reelIndex][rowIndex];
            }
        },
        imagePath(rowIndex, reelIndex) {
            let symbol = this.getSymbol(rowIndex, reelIndex);

            try {
                return require(`@/assets/games/slots/${this.symbolsPath}/${symbol}.png`);
            } catch (e) {
                return require(`@/assets/games/slots/default/${symbol}.png`);
            }
        }
    },
    mounted() {
        this.$store.dispatch('workspace/setHeight', this.rows * this.symbol.size);
        this.$store.dispatch('workspace/setWidth', this.reels * this.symbol.size);
    }
}
</script>

<style scoped>
table {
    border-collapse: collapse;
    margin: auto;
}

td {
    border: 1px solid #dee2e6 !important;
}

.layout__symbol {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 80px;
    height: 80px;

    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
}
</style>
