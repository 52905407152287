<template>
    <workspace-wrapper>
        <template #header>
            <default-slot-header :bet="stateData.currentSpinResult.bet"
                                 :lines="stateData.currentSpinResult.linesCount"
                                 :stake="stateData.currentSpinResult.stake"
            />
            <free-spins-slot-header v-if="stateData.currentSpinResult.isFreeSpin"
                                    v-bind="stateData.currentSpinResult.bonusFreeSpins"
            />
        </template>

        <template #workspace>
            <tizona-workspace :layout="stateData.currentSpinResult.layout"
                              :symbols-path="symbolsPath"
                              :positions="wildPositions"
            />
        </template>
    </workspace-wrapper>
</template>

<script>
import WorkspaceWrapper from "../Common/WorkspaceWrapper";
import DefaultSlotHeader from "./Common/Header/DefaultSlotHeader";
import FreeSpinsSlotHeader from "./Common/Header/FreeSpinsSlotHeader";
import TizonaWorkspace from "./Custom/TizonaWorkspace";

export default {
    props: {
        stateData: {
            type: Object,
            required: true,
            default() {
                return {
                    currentSpinResult: {
                        balanceBefore: null,
                        bet: null,
                        linesCount: null,
                        stake: null,
                        layout: null,
                        isFreeSpin: null,
                        bonuses: [{
                            positions: []
                        }],
                        bonusFreeSpins: {
                            totalWin: null,
                            totalFreeSpins: null,
                            currentFreeSpin: null,
                            freeSpinsAwardedCount: null
                        }
                    }
                }
            }
        }
    },
    data() {
        return {
            symbolsPath: 'tizona'
        }
    },
    computed: {
        wildPositions() {
            if (this.stateData.currentSpinResult.bonuses[this.stateData.currentSpinResult.totalBet]) {
                return this.stateData.currentSpinResult.bonuses[this.stateData.currentSpinResult.totalBet].positions;
            }

            return [];
        }
    },
    components: {
        TizonaWorkspace,
        WorkspaceWrapper,
        DefaultSlotHeader,
        FreeSpinsSlotHeader
    }
}
</script>

<style scoped>

</style>
